import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvorySheet from "@/model/sheets/IvorySheet";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";
import * as ChordManager from "@/managers/ChordManager";
import { StaveEnum } from "@/model/sheets/StaveEnum";
import * as VexflowUtils from "@/utils/VexflowUtils";
import VF, {
  Accidental,
  Annotation,
  Articulation,
  Beam,
  ClefNote,
  Dot,
  Modifier,
  Note,
  NoteSubGroup,
  Stave,
  StaveConnector,
  StaveNote,
  StaveTie,
  StemmableNote,
  Stroke,
  Vibrato,
  Voice,
} from "vexflow";
import { nextTick } from "vue";
import IvoryStave from "@/model/sheets/IvoryStave";
import IvoryTimeSignature from "@/model/sheets/IvoryTimeSignature";
import Staff from "./Staff";
import GrandStaff from "./GrandStaff";
import { StaveNoteModifier } from "@/model/sheets/StaveNoteModifier";

export let SHEET_Y_OFFSET = 220;

export let PADDING_X = 50;

export let GAP_BETWEEN_GRAND_STAFF = 280;

export let GAP_BETWEEN_STAFF = 120;

export let STAVE_PER_LINE = 2;

export default class SheetDrawerSVG {
  container: HTMLDivElement;
  sheet: IvorySheet | null = null;

  context: VF.RenderContext | null;

  allNotes: Map<IvoryStaveNote, Note> = new Map();

  allStaves: Map<Stave, Staff> = new Map();

  x: number = 0;
  y: number = 0;

  grandStaffs: Map<number, GrandStaff> = new Map();

  renderer: VF.Renderer | null = null;

  firstStaveLastClefChange: StaveEnum | null = null;
  secondStaveLastClefChange: StaveEnum | null = null;

  constructor(container: HTMLDivElement) {
    this.container = container;
    this.context = null;
  }

  getTimeSignature(barId: number): IvoryTimeSignature | undefined {
    var ts = this.sheet!.timeSignatures.find((x) => x.barId <= barId);

    return ts;
  }
  buildStaff(
    ivoryStave: IvoryStave,
    bar: IvoryMeasure,
    barX: number,
    barY: number
  ): Staff | null {
    if (bar.staveChange != null) {
      if (ivoryStave.id == 0) {
        this.firstStaveLastClefChange = bar.staveChange;
      } else {
        this.secondStaveLastClefChange = bar.staveChange;
      }
    }

    var clef =
      ivoryStave.id == 0
        ? this.getClef(this.firstStaveLastClefChange!)
        : this.getClef(this.secondStaveLastClefChange!);

    var stave = new VF.Stave(barX, barY, GrandStaff.MIN_STAFF_WIDTH);

    var ts = this.getTimeSignature(bar.number);

    if (ts?.barId == bar.number) {
      stave.addTimeSignature(
        ts.numberOfBeatPerMeasure + "/" + ts.beatNoteValue
      );
    }

    if (this.x == 0) {
      stave.addKeySignature(this.sheet!.key);
      stave.addClef(clef);
    } else {
      if (bar.staveChange != null) {
        stave.addClef(this.getClef(bar.staveChange));
      }
    }

    var notes: VF.StemmableNote[] = [];

    for (let ivoryStaveNote of bar.notes) {
      var beamNotes: IvoryStaveNote[] = [];

      if (ivoryStaveNote.beamId != null) {
        beamNotes = bar.notes.filter((x) => x.beamId == ivoryStaveNote.beamId);
      }

      var staveNote: StemmableNote | null = null;

      if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.Grace)) {
        staveNote = new VF.GraceNote({
          clef: clef,
          keys: ivoryStaveNote.keys,
          duration: ivoryStaveNote.duration,
          stem_direction: 1,
          slash: true,
        }).setStave(stave);
      } else {
        staveNote = new VF.StaveNote({
          clef: clef,
          keys: ivoryStaveNote.keys,
          duration: ivoryStaveNote.duration,
          stem_direction: this.getSteamDirection(
            ivoryStaveNote,
            clef,
            beamNotes
          ),
        }).setStave(stave);
      }

      notes.push(staveNote);

      var tempoChange = this.sheet!.tempoChanges.find((x) =>
        ivoryStaveNote.indexes.some((y) => y == x.noteIndex)
      );

      if (tempoChange) {
        staveNote.setStyle({ fillStyle: "gray", strokeStyle: "gray" });
        staveNote.addModifier(
          new VF.Annotation("♪ " + Math.round(tempoChange.tempo * 100) / 100)
            .setFont("Arial", 10, "normal")
            .setVerticalJustification(VF.Annotation.VerticalJustify.TOP)
            .setStyle({ fillStyle: "rgb(160,160,160)" })
        );
      }

      if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.RollDown)) {
        staveNote.addStroke(0, new VF.Stroke(4, { all_voices: true }));
      } else if (ivoryStaveNote.modifiers.includes(StaveNoteModifier.RollUp)) {
        staveNote.addStroke(0, new VF.Stroke(3, { all_voices: true }));
      }
    }

    for (let i = 0; i < bar.notes.length; i++) {
      var n = bar.notes[i];

      this.allNotes.set(n, notes[i]);

      if (n.duration.includes("d")) {
        Dot.buildAndAttach([notes[i]]);
      }

      if (n.keys.length >= 3) {
        var ids = n.keys.map((x) => VexflowUtils.getNoteId(x));
        /*var chord = ChordManager.getChordNameFromIds(ids, false);

        if (chord) {
          notes[i].addModifier(
            new VF.Annotation(chord!)
              .setFont("Arial", 10, "normal")
              .setStyle({ fillStyle: "rgb(120,120,120)" })
          );
        } */
      }
    }

    var ts = this.getTimeSignature(bar.number);
    var voice = new VF.Voice({
      num_beats: ts!.numberOfBeatPerMeasure!,
      beat_value: ts!.beatNoteValue!,
    })
      .setStave(stave)
      .setStrict(false)
      .addTickables(notes)
      .setMode(Voice.Mode.SOFT);

    VF.Accidental.applyAccidentals([voice], this.sheet!.key);

    var beams = Beam.generateBeams(notes);

    stave.setContext(this.context!);

    voice.setContext(this.context!);

    if (beams.length > 0) {
      beams.forEach((x) => x.setContext(this.context!));
    }
    /* if (notes.length > 0) {
      let tp = new VF.Tuplet(notes, {
        beats_occupied: 3,
        bracketed: true,
        location: -20,
        ratioed: true,
      });

      tp.setContext(this.context!).draw();
    } */

    let vexMeasure = new Staff(
      ivoryStave.id,
      bar,
      stave,
      beams,
      [],
      voice,
      barX,
      barY
    );

    return vexMeasure;
  }

  getSteamDirection(
    note: IvoryStaveNote,
    clef: string,
    beamNotes: IvoryStaveNote[]
  ) {
    let keyRef = clef == "treble" ? 50 : 29;

    if (note.beamId != null) {
      if (beamNotes.some((x) => VexflowUtils.getNoteId(x.keys[0]) > keyRef)) {
        return -1;
      } else {
        return 1;
      }
    }

    var num = VexflowUtils.getNoteId(note.keys[0]);
    if (num > keyRef) {
      return -1;
    }
    return 1;
  }

  getClef(staveEnum: StaveEnum) {
    switch (staveEnum) {
      case StaveEnum.Bass:
        return "bass";
      case StaveEnum.Treble:
        return "treble";
    }
  }
  initialize() {
    this.renderer = new VF.Renderer(this.container!, VF.Renderer.Backends.SVG);

    this.context = this.renderer.getContext();
  }
  drawTitle() {
    this.context!.setFont("Kanit", 25, "normal").setBackgroundFillStyle(
      "black"
    );

    let titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(this.sheet!.title).width / 2;

    let titleY = 80;

    this.context!.fillText(this.sheet!.title, titleX, titleY);

    this.context!.setFont("Kanit", 12, "normal").setFillStyle("gray");

    let text = "Detected key : " + this.sheet!.key;

    titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(text).width / 2;

    titleY = 105;

    this.context!.fillText(text, titleX, titleY);

    var infoMessage =
      "This piano score is automatically generated and still a work in progress. We are actively improving it, and appreciate your understanding as we refine it.";

    titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(infoMessage).width / 2;

    titleY = 125;

    this.context?.setFillStyle("orange");

    this.context!.fillText(infoMessage, titleX, titleY);

    var infoMessage2 =
      "Only the first measures are displayed. Please use the player to listen to the full piece.";

    titleX =
      this.container.clientWidth / 2 -
      this.context!.measureText(infoMessage2).width / 2;

    titleY = 145;

    this.context?.setFillStyle("orange");

    this.context!.fillText(infoMessage2, titleX, titleY);

    this.context?.setFillStyle("black");
  }

  build(sheet: IvorySheet) {
    this.sheet = sheet;

    var maxLength = 0;

    this.sheet?.firstStave.bars.forEach((x) => {
      maxLength = Math.max(maxLength, x.notes.length);
    });
    this.sheet?.secondStave.bars.forEach((x) => {
      maxLength = Math.max(maxLength, x.notes.length);
    });

    STAVE_PER_LINE = 4;

    if (maxLength >= 8) {
      STAVE_PER_LINE = 3;
    }
    if (maxLength >= 15) {
      STAVE_PER_LINE = 2;
    }

    GrandStaff.MIN_STAFF_WIDTH =
      this.container.offsetWidth / STAVE_PER_LINE -
      PADDING_X / (STAVE_PER_LINE / 2);

    this.x = 0;
    this.y = 0;

    this.firstStaveLastClefChange = this.sheet.firstStave.staveEnum;
    this.secondStaveLastClefChange = this.sheet.secondStave.staveEnum;

    this.grandStaffs.clear();
    this.allNotes.clear();
    this.allStaves.clear();

    let barCount = Math.min(
      this.sheet?.firstStave.bars.length!,
      this.sheet?.secondStave.bars.length!
    );

    let xPx = PADDING_X;

    let yPx = SHEET_Y_OFFSET;

    for (let barId = 0; barId < barCount; barId++) {
      let trebleBar = this.sheet?.firstStave.bars[barId]!;

      let bassBar = this.sheet?.secondStave.bars[barId]!;

      var firstMeasure = this.buildStaff(
        this.sheet?.firstStave,
        trebleBar,
        xPx,
        yPx
      );

      var secondMeasure = this.buildStaff(
        this.sheet?.secondStave,
        bassBar,
        xPx,
        yPx + GAP_BETWEEN_STAFF
      );

      var group = new GrandStaff(
        barId,
        this.x,
        this.y,
        firstMeasure!,
        secondMeasure!
      );
      group.buildConnectors(this.context!);
      group.format();

      this.grandStaffs.set(barId, group);

      this.allStaves.set(firstMeasure!.stave, firstMeasure!);
      this.allStaves.set(secondMeasure!.stave, secondMeasure!);

      this.x++;

      xPx += group.width!;

      if (this.x >= STAVE_PER_LINE) {
        this.x = 0;
        this.y++;
        xPx = PADDING_X;
        yPx += GAP_BETWEEN_GRAND_STAFF;
      }
    }

    this.buildTies();

    var totalHeight =
      (this.y + 1) * GAP_BETWEEN_GRAND_STAFF +
      SHEET_Y_OFFSET +
      GAP_BETWEEN_STAFF;

    this.renderer!.resize(this.container.offsetWidth, totalHeight);
  }

  draw() {
    this.context?.clear();

    for (let group of this.grandStaffs.values()) {
      group.draw(this.context!);
    }

    nextTick(() => {
      this.drawTitle();
    });
  }
  bindEvents() {
    for (let group of this.grandStaffs.values()) {
      group.bindEvents();
    }
  }
  buildTies() {
    const groupedByTies = Array.from(this.allNotes.keys()).reduce<
      Map<number, IvoryStaveNote[]>
    >(
      (res, note) => {
        const tieId = note.tieId;

        if (tieId == null) {
          return res;
        }

        if (!res.has(tieId)) {
          res.set(tieId, []);
        }

        res.get(tieId)!.push(note);

        return res;
      },

      new Map<number, IvoryStaveNote[]>()
    );

    for (let group of groupedByTies) {
      var notes = group[1];

      for (let i = 0; i < notes.length - 1; i++) {
        var n1 = this.allNotes.get(notes[i]);
        var n2 = this.allNotes.get(notes[i + 1]);

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie = new StaveTie({ first_note: n1, last_note: null });
        } else {
          var tie = new StaveTie({ first_note: n1, last_note: n2 });
        }

        tie.setContext(this.context!);

        this.allStaves.get(n1?.getStave()!)?.ties.push(tie);
        this.allStaves.get(n1?.getStave()!)?.ties.push(tie);

        if (n1?.getStave()?.getY() != n2?.getStave()?.getY()) {
          var tie2 = new StaveTie({ first_note: null, last_note: n2 });
          tie2.setContext(this.context!);

          this.allStaves.get(n2?.getStave()!)?.ties.push(tie2);
        }
      }
    }
  }
}
