import { defineStore } from "pinia";
import { HomePageEnum } from "./HomePageEnum";
import GenreRecord from "@/model/genres/GenreRecord";
import IvorySong from "@/model/songs/IvorySong";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";
import SheetDrawerSVG from "@/managers/SheetDrawerSVG";
import SongPlayer from "@/managers/SongPlayer";
import IvorySheet from "@/model/sheets/IvorySheet";
import { getState } from "./IvoryState";
import * as IvoryApi from "@/IvoryApi";
import * as SoundManager from "@/managers/SoundManager";
import IvoryNote from "@/model/songs/IvoryNote";
import { StaveNote } from "vexflow";
import Keyboard from "@/components/player/keys/Keyboard.vue";
import * as Snackbars from "@/utils/Snackbars";
import SheetGenerationResult from "@/model/sheets/SheetGenerationResult";

interface SheetState {
  songId: number | null;
  loaded: boolean;
  result: SheetGenerationResult | null;
  drawer: SheetDrawerSVG | null;
  debugText: string;
  songPlayer: SongPlayer | null;
  noteMapping: Map<number, IvoryStaveNote[]>;
  view: any;
  showKeyboard: boolean;
  generationInterval: number | null;
}

const defaultState = {
  songId: null,
  loaded: false,
  song: null,
  drawer: null,
  debugText: "Loading...",
  songPlayer: null,
  noteMapping: new Map(),
  view: null,
  showKeyboard: false,
  sheetGenerationTask: null,
  generationInterval: null,
  result: null,
};
export const getSheetState = defineStore("SheetState", {
  state: (): SheetState => {
    return Object.assign({}, defaultState);
  },
  actions: {
    async initialize(
      songId: number,
      tempo: string,
      svgContainer: HTMLDivElement
    ) {
      var appState = getState();

      if (appState.account == null) {
        appState.account = await IvoryApi.getLoggedAccount();

        if (appState.account != null) {
          appState.onAccountReceived();
        }
      }

      appState.computeKeyboardSize();

      this.debugText = "Generating sheet... 🎶";

      this.result = await IvoryApi.getSheet(songId);

      await SoundManager.initialize("ivory-keys", (v: number) => {
        this.debugText = Math.round(v * 100) + "%";
      });

      this.debugText = "...";

      this.drawer = new SheetDrawerSVG(svgContainer);

      this.drawer!.initialize();
      this.drawer!.build(this.result?.sheet!);
      this.drawer!.draw();
      this.drawer!.bindEvents();
      this.buildNoteMapping();

      this.songPlayer = new SongPlayer(
        this.result?.data!,
        this.onNoteStart,
        this.onNoteEnd,
        this.onNoteEndReal
      );

      this.loaded = true;
    },

    downloadXml() {
      Snackbars.display("Export to XML is not supported yet.");
      return;
      if (this.drawer?.sheet != null) {
        window.open(IvoryApi.getMusicXmlDownloadLink(this.songId!));
      } else {
        Snackbars.display("Please wait for sheet generation to complete.");
      }
    },
    openInPlayer() {
      getState().playSongInNewTab(this.songId!);
    },
    toggleShowKeyboard() {
      this.showKeyboard = !this.showKeyboard;
    },
    getKeyboard(): Keyboard {
      return this.view.getKeyboard();
    },
    openEditorBar() {
      this.view.openEditorBar();
    },
    play() {
      this.songPlayer?.play();
    },
    stop() {
      var keyboard = this.getKeyboard();
      keyboard.removeInputs();

      this.songPlayer?.stop();

      for (let note of this.drawer!.allNotes!.values()) {
        // abstract more
        note.setStyle({ fillStyle: "black", strokeStyle: "black" });
      }

      this.drawer!.draw();

      SoundManager.stop();
    },
    onNoteEndReal(note: IvoryNote) {
      this.getKeyboard()!.removeSound(note.number);
    },
    onNoteEnd(note: IvoryNote) {
      let sheetNotes = this.noteMapping.get(note.index)!;

      this.getKeyboard()!.removeInput(note.number);

      for (let sheetNote of sheetNotes) {
        var staveNote = this.drawer?.allNotes.get(sheetNote) as StaveNote;

        staveNote.setStyle({ fillStyle: "black", strokeStyle: "black" });

        staveNote.draw();
      }
    },
    onNoteStart(note: IvoryNote) {
      var keyboard = this.getKeyboard();

      var appState = getState();

      keyboard!.addInput(note.number, true, note.velocity, note.leftHand);

      let sheetNotes = this.noteMapping.get(note.index)!;

      for (let sheetNote of sheetNotes) {
        var staveNote = this.drawer?.allNotes.get(sheetNote) as StaveNote;

        var color = appState.getNoteColorHex();

        if (note.leftHand) {
          color = appState.getNoteColorLHHex();
        }

        staveNote.setStyle({ fillStyle: color, strokeStyle: color });

        staveNote.draw();
      }
    },
    buildNoteMapping() {
      var arr = Array.from(this.drawer!.allNotes!.keys());

      for (let note of this.result?.data?.notes!) {
        var sheetNotes = arr.filter((x) =>
          x.indexes.some((x) => x == note.index)
        );

        this.noteMapping.set(note.index, []);

        for (let sheetNote of sheetNotes) {
          this.noteMapping.get(note.index)?.push(sheetNote);
        }
      }
    },
  },
});
