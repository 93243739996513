import IvorySong from "./songs/IvorySong";

export enum TranscriptionStatus {
  Ok,
  AlreadyProcessing,
  AlreadyExists,
  NotAuthentificated,
  InvalidUrl,
  Error,
  NotSubscribed,
  MidiImported,
  TooLong,
  Conflict,
}

export default class TranscriptionResult {
  public status: TranscriptionStatus = TranscriptionStatus.Error;
}
