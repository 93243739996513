import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f5aa6a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item light" }
const _hoisted_2 = { class: "item-row" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-center justify-center p12"
}
const _hoisted_4 = { class: "meta-row" }
const _hoisted_5 = { class: "f12" }
const _hoisted_6 = {
  style: {"gap":"12px"},
  class: "item-row"
}
const _hoisted_7 = { class: "f14 hbold" }
const _hoisted_8 = { class: "flex g6 bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.task.status.includes('Error'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_HalfCircleSpinner, {
              size: 30,
              color: "#c4c4c4"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.task.status.includes('Error'))
        ? (_openBlock(), _createBlock(_component_IvoryIcon, {
            key: 1,
            icon: "error"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("b", null, _toDisplayString(_ctx.task.title), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.task.youtubeUrl), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.task.status), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", null, _toDisplayString(_ctx.task.queuePosition), 1),
        _createTextVNode(" / "),
        _createElementVNode("span", null, _toDisplayString(_ctx.task.queueTotal), 1)
      ])
    ])
  ]))
}