import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf3754b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col align-center justify-center" }
const _hoisted_2 = {
  key: 1,
  class: "flex align-center"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  style: {"width":"40px"},
  class: "light f12"
}
const _hoisted_5 = {
  key: 2,
  class: "light f14"
}
const _hoisted_6 = { class: "text-col light f10 p12" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryLogo = _resolveComponent("IvoryLogo")!
  const _component_HollowDotsSpinner = _resolveComponent("HollowDotsSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.loadingLayout),
      class: "center"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IvoryLogo),
        (!_ctx.error && _ctx.loadingPercent == 0)
          ? (_openBlock(), _createBlock(_component_HollowDotsSpinner, {
              key: 0,
              color: "#c4c4c4",
              "animation-duration": 700
            }))
          : _createCommentVNode("", true),
        (!_ctx.error && _ctx.loadingPercent != 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("progress", {
                class: "progress",
                value: _ctx.loadingPercent,
                max: "100"
              }, null, 8, _hoisted_3),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.loadingPercent) + " %", 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true)
      ])
    ], 4),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", null, "Version : " + _toDisplayString(_ctx.Constants.Version) + " - Release", 1),
      (_ctx.state.loggedIn())
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createTextVNode("Username : "),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.state.account?.username), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}