import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a4ed06a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ivory-header" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "flex grow g24 align-center" }
const _hoisted_4 = { class: "playback-row grow" }
const _hoisted_5 = { class: "flex g0" }
const _hoisted_6 = { class: "icons-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IvoryIcon, {
        size: 50,
        icon: "grand-piano"
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_IvoryIcon, {
              size: 35,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.stop())),
              icon: "stop"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Stop ")
              ]),
              _: 1
            }),
            _createVNode(_component_IvoryIcon, {
              size: 35,
              ref: "pauseIcon",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.play())),
              icon: "play"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Play")
              ]),
              _: 1
            }, 512)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_IvoryIcon, {
        onClick: _ctx.downloadXml,
        icon: "download"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Download")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_IvoryIcon, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.toggleShowKeyboard())),
        icon: "keyboard"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Show keyboard")
        ]),
        _: 1
      }),
      _createVNode(_component_IvoryIcon, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.openEditorBar())),
        icon: "layers"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Menu")
        ]),
        _: 1
      }),
      _createVNode(_component_IvoryIcon, {
        icon: "home",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goLibrary()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Home ")
        ]),
        _: 1
      })
    ])
  ]))
}