import { Formatter, RenderContext, StaveConnector } from "vexflow";
import VexMeasure from "./Staff";
import * as SheetDrawerSVG from "./SheetDrawerSVG";

export default class GrandStaff {
  static MIN_STAFF_WIDTH = 0;

  measure1: VexMeasure;
  measure2: VexMeasure;

  x: number;
  y: number;

  width: number | null = null;

  barId: number;

  connectors: StaveConnector[] = [];

  constructor(
    barId: number,
    x: number,
    y: number,
    measure1: VexMeasure,
    measure2: VexMeasure
  ) {
    this.x = x;
    this.y = y;
    this.barId = barId;
    this.measure1 = measure1;
    this.measure2 = measure2;
  }

  bindEvents() {
    this.measure1.bindEvents();
    this.measure2.bindEvents();
  }

  buildConnectors(context: RenderContext) {
    if (this.x == 0) {
      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(3);

      brace.setContext(context);

      this.connectors.push(brace);

      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(1);

      brace.setContext(context);

      this.connectors.push(brace);
    }

    if (this.x == SheetDrawerSVG.STAVE_PER_LINE - 1) {
      var brace = new StaveConnector(
        this.measure1!.stave,
        this.measure2!.stave
      ).setType(0);
      brace.setContext(context);
      this.connectors.push(brace);
    }
  }
  format() {
    const formatter = new Formatter();
    formatter.joinVoices([this.measure1.voice, this.measure2.voice]);

    var justifyWidth = GrandStaff.MIN_STAFF_WIDTH;

    var staveWidth = GrandStaff.MIN_STAFF_WIDTH;

    var modifierX = Math.max(
      this.measure1.stave.getModifierXShift(),
      this.measure2.stave.getModifierXShift()
    );

    let minWidth = formatter.preCalculateMinTotalWidth([
      this.measure1.voice,
      this.measure2.voice,
    ]);

    var offset = 20;

    justifyWidth -= modifierX + offset;

    // Dynamically calculate the minimum required width

    try {
      formatter.format(
        [this.measure1.voice, this.measure2.voice],
        justifyWidth
      );
    } catch {
      console.log(this.barId + "error");
    }

    this.measure1.stave.setWidth(staveWidth);
    this.measure2.stave.setWidth(staveWidth);

    this.width = staveWidth;
  }

  draw(ctx: RenderContext) {
    this.measure1.draw(ctx);
    this.measure2.draw(ctx);

    for (let connectors of this.connectors) {
      connectors.draw();
    }
  }
}
